@import 'colors';

.welcome-container {
  height: 100vh;
  scroll-snap-align: start;
  padding: 0 15vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 10vh 20vw;
    height: 80vh;
  }
}

.personal-info {
  h1 {
    padding-top: 53vh;
    font-size: 400%;
    margin-bottom: 0px;

    @media (max-width: 1024px) {
      font-size: 300%;
      padding-top: 0;
    }
  }

  .typewriter {
    background-color: $background;
    color: $highlight;
    font-size: 200%;

    @media (max-width: 1024px) {
      font-size: 150%;
    }
  }

  .typewriter-cursor {
    font-size: 200%;

    @media (max-width: 1024px) {
      font-size: 150%;
    }
  }
}

.personal-description {
  padding-top: 5vh;

  .skills {
    p {
      margin: 0px;
    }
  }

  h3 {
    margin-top: 5vh;
    font-size: 150%;

    @media (max-width: 1024px) {
      font-size: 120%;
    }
  }

  p {
    font-size: 120%;

    @media (max-width: 1024px) {
      font-size: 100%;
    }
  }
}
