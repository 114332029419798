@import 'colors';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: $background;
  font-family: 'Barlow Condensed', sans-serif;
  color: $highlight;
  cursor: url('./assets/cursor/cursor.svg') 9 9, auto;
}

h2 {
  font-size: 200%;
}
