@import 'colors';

.app {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.link-container {
  height: 100vh;
  width: 5vw;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 5;
  background-color: $background;
  transition: width 0.7s ease-in-out;
  transition-delay: 0.7s;
  transition-property: width, z-index;

  @media (max-width: 1024px) {
    width: 15vw;
  }

  .side-link-container {
    height: 100vh;
    background: linear-gradient($highlight, $highlight) no-repeat center/1px 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.7s ease-in-out;

    &.expanded {
      background: linear-gradient($highlight, $highlight) no-repeat center/1px 100%;
    }
  }

  &.expanded {
    width: 100vw;
    z-index: 6;

    .about-link,
    .contact-link {
      color: $highlight;
    }
  }

  &.left {
    left: 0;
    justify-content: flex-end;
  }

  .about-link {
    transform: rotate(-90deg);
    background-color: $background;
    padding: 0 12px;
  }

  &.right {
    right: 0;
    justify-content: flex-start;
  }

  .contact-link {
    transform: rotate(90deg);
    background-color: $background;
    padding: 0 12px;
  }
}

.link {
  height: auto;
  overflow: visible;
  color: $highlight-50;
  text-decoration: none;
  text-align: center;
  z-index: 5;
  cursor: url('./assets/cursor/cursorClick.svg') 9 9, auto;
  transition: all 1s linear;

  &:hover {
    color: $highlight;
    transition: all 0.2s linear;
  }
}

.hidden-link {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 1s linear;
}

.home-link {
  position: fixed;
  top: 3vh;
  left: 45vw;
  width: 10vw;
  color: $background-50;
  cursor: url('./assets/cursor/cursorClickWhite.svg') 9 9, auto;

  &:hover {
    color: $background;
  }

  &.hidden-link {
    top: 1vh;
    transition: all 1s linear;
  }
}

.projects-link {
  position: fixed;
  bottom: 3vh;
  left: 45vw;
  width: 10vw;

  &.hidden-link {
    bottom: 5vh;
    transition: all 1s linear;
  }
}
