@import 'colors';

.projects-container {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $highlight;
  cursor: url('./assets/cursor/cursorWhite.svg') 9 9, auto;
  position: relative;

  @media (max-width: 1024px) {
    align-items: flex-end;
  }

  .projects-header {
    position: absolute;
    top: 15vh;
    right: 90vw;
    font-size: 130%;
    color: $background;
    border-right: solid 0.5px $background;
    min-height: 10vh;
    margin-right: 1vw;
    padding-right: 1vw;
    padding-top: 2vh;
    text-align: right;

    @media (max-width: 1024px) {
      position: unset;
      top: 0;
      right: 0;
      border-right: none;
      border-bottom: solid 0.5px $background;
      min-height: unset;
      margin-right: 10vw;
      margin-top: -5vh;
    }
  }

  .projects-section {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 0 10vw;
      overflow: unset;
    }

    .project-container {
      flex: 1;
      transition: transform 0.2s ease-in-out;
      transform: translateY(4vh);

      &:hover {
        transform: translateY(0);
      }

      &.hidden {
        transition: transform 0.5s ease-in-out;
        transform: translateY(100vh);
      }

      &.selected {
        transition: transform 0.5s ease-in-out 0.5s;
        transform: translateY(100vh);
        width: 0;

        @media (max-width: 1024px) {
          width: unset;
        }
      }
    }

    .project-image {
      width: 100%;
      height: 80vh;
      object-fit: cover;
      filter: grayscale(100%) invert(20%) sepia(45%) saturate(433%) hue-rotate(131deg) brightness(30%) contrast(96%);
      transition: all 0.2s ease-in-out;
      cursor: url('./assets/cursor/cursorClickWhite.svg') 9 9, auto;

      @media (max-width: 1024px) {
        width: 100%;
        height: 10vh;
      }

      &:hover {
        filter: none;
      }
    }

    .project-header {
      font-size: 150%;
      color: $background;
      margin-top: 4px;
      border-top: solid 0.5px $background;
      width: 50%;
      padding: 0 4px;
    }
  }

  .selected-project-container {
    position: absolute;
    top: 15vh;
    left: 10vw;
    color: $background;
    width: 80vw;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out 0.7s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.hidden {
      transform: translateX(-100vw);
    }

    .selected-project-top {
      display: flex;

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }

    .selected-project-image {
      height: 55vh;
      width: auto;
      object-fit: cover;
      margin-right: 5vw;

      @media (max-width: 1024px) {
        margin-bottom: 8px;
        width: 80vw;
        height: auto;
      }
    }

    .selected-project-copy {
      font-size: 110%;
      margin-bottom: 24px;
    }

    .selected-project-return {
      text-align: right;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: solid 0.5px $background;
      color: $background-50;
      cursor: url('./assets/cursor/cursorClickWhite.svg') 9 9, auto;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $background;
      }
    }

    .selected-project-link {
      cursor: url('./assets/cursor/cursorClickWhite.svg') 9 9, auto;
      font-size: 120%;
      text-decoration: none;
      padding-top: 8px;
      padding-right: 8px;
      margin-top: 8px;
      border-top: solid 0.5px $background;
      color: $background-50;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $background;
      }
    }
  }
}
